<template>
  <div>
      <h2>Market Pay Trends</h2>
      <b-table 
        bordered
        :items="marketPayTrendsList" 
        :fields="marketPayTrendsFields"
        ref="marketPayTrendsTable">
           <template #cell(businessSectorName)="row">
               <b-container fluid>
                  <b-row class="my-1" v-for="(name,index) in row.item.BusinessSectors" :key="index" >
                   <b-col >
                      {{name.businessSectorName}}
                  </b-col>
                 </b-row>
               </b-container>
           </template>
           <template #cell(min)="row">
               <b-container fluid>
                  <b-row class="my-1" v-for="(name,index) in row.item.BusinessSectors" :key="index" >
                   <b-col >
                      {{name.min}}
                  </b-col>
                 </b-row>
               </b-container>
           </template>
           <template #cell(average)="row">
               <b-container fluid>
                  <b-row class="my-1" v-for="(name,index) in row.item.BusinessSectors" :key="index" >
                   <b-col >
                      {{name.average}}
                  </b-col>
                 </b-row>
               </b-container>
           </template>
           <template #cell(median)="row">
               <b-container fluid>
                  <b-row class="my-1" v-for="(name,index) in row.item.BusinessSectors" :key="index" >
                   <b-col >
                      {{name.median}}
                  </b-col>
                 </b-row>
               </b-container>
           </template>
           <template #cell(max)="row">
               <b-container fluid>
                  <b-row class="my-1" v-for="(name,index) in row.item.BusinessSectors" :key="index" >
                   <b-col >
                      {{name.max}}
                  </b-col>
                 </b-row>
               </b-container>
           </template>
           <template #cell(Actions)="row">
             <b-container fluid>
                  <b-row class="my-1" v-for="(name,index) in row.item.BusinessSectors" :key="index" >
                   <b-col >
                      <b-button  squared variant="primary" title="Edit"
                      @click="editMarketPayTrends(name)">
                         Edit
                      </b-button>
                  </b-col>
                 </b-row>
               </b-container>
           </template>
      </b-table>
      <b-modal id="EditMarketPayTrend" title="Edit Market Pay Trends" hide-footer hide-backdrop>
        <form ref="form" @submit.stop.prevent="UpdateMarketPayTrend">
            <b-form-group
                label="Business Sector:"
                label-for="BusinessSector" >
                  {{this.form.businessSector}}
            </b-form-group>
            <b-form-group
              label="Min"
              label-for="txtMin" >
                  <b-form-input
                      type="number"
                      id="txtMin"
                      name="txtMin"
                      placeholder=""
                      v-model="$v.form.min.$model"
                      :state="validateState('min')"
                      aria-describedby="min-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="min-feedback">
                      This is a required field
                  </b-form-invalid-feedback>
              </b-form-group>
               <b-form-group
              label="Average: "
              label-for="txtMin" >
                  <b-form-input
                      type="number"
                      id="txtAvg"
                      name="txtAvg"
                      placeholder=""
                      v-model="$v.form.average.$model"
                      :state="validateState('average')"
                      aria-describedby="average-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="average-feedback">
                      This is a required field
                  </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
              label="Median: "
              label-for="txtMedian" >
                  <b-form-input
                      type="number"
                      id="txtAvg"
                      name="txtAvg"
                      placeholder=""
                      v-model="$v.form.median.$model"
                      :state="validateState('median')"
                      aria-describedby="median-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="median-feedback">
                      This is a required field
                  </b-form-invalid-feedback>
              </b-form-group>
               <b-form-group
              label="Max: "
              label-for="txtMax" >
                  <b-form-input
                      type="number"
                      id="txtMax"
                      name="txtMax"
                      placeholder=""
                      v-model="$v.form.max.$model"
                      :state="validateState('max')"
                      aria-describedby="max-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="max-feedback">
                      This is a required field
                  </b-form-invalid-feedback>
              </b-form-group>
               <b-button type="submit" variant="primary">Update</b-button>
        </form>
      </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  computed: {
     surveyYear(){
            return this.$store.getters.surveyYear;
        },
  },
    validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
  data(){
    return{
      marketPayTrendsList:[],
      marketPayTrendsFields:[
         { key: 'Industry', label: 'Industry'},
         { key: 'businessSectorName', label: 'Business Sector'},
         { key: 'min', label: 'Min'},
         { key: 'average', label: 'Average'},
         { key: 'median', label: 'Median'},
         { key: 'max', label: 'Max'},
         'Actions'],
          form: {
                businessSectorId:null,
                businessSector:null,
                min:null,
                average:null,
                median:null,
                max:null
            },
    }
  },
  validations: {
    form: {
            min: {
                required,
            },
             average: {
                required,
            },
             median: {
                required,
            },
             max: {
                required,
            },
        }
  },
  methods:{
      editMarketPayTrends(item)
      {
        this.form.businessSectorId = item.businessSectorId;
        this.form.businessSector = item.businessSectorName;
        this.form.min  = item.min == 0 ? null:item.min;
        this.form.average = item.average == 0?null:item.average;
        this.form.median = item.median == 0?null:item.median,
        this.form.max = item.max == 0?null:item.max;
        this.currentEditedItem = item;
        this.$bvModal.show('EditMarketPayTrend');
      },
      UpdateMarketPayTrend(){
        this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            console.log(this.form.min);
            this.$axios({
                  method: 'put',
                  url: `${this.$baseURL}/MarketPayTrends`,
                  headers: {'Content-Type': 'application/json' },
                  data:{
                    BusinessSectorId:this.form.businessSectorId,
                    SurveyYear:this.surveyYear,
                    Min:Number(this.form.min),
                    Average:Number(this.form.average),
                    Median:Number(this.form.median),
                    Max:Number(this.form.max)
                  }
              }).then((response)=>{
                  
                    if(JSON.parse(response.data.status) == true)
                    {
                        this.fillPayTrendsTable(response.data.marketPayTrends)
                        this.$bvModal.hide('EditMarketPayTrend');
                        this.$refs.marketPayTrendsTable.refresh();
                    }
                });

      },
      getPayTrends(){
         this.$axios({
                method: 'get',
                url: `${this.$baseURL}/MarketPayTrends`,
                params:{
                  surveyYear:this.surveyYear
                }
            })
             .then((response)=>{
              //  console.log(response);
               if(JSON.parse(response.data.status) == true){
                   this.fillPayTrendsTable(response.data.marketPayTrends)
               }
               else{
                 this.marketPayTrendsList = []
               }

                 
             });
      },
      fillPayTrendsTable(data){
        let w = []
                    data.forEach(element => {
                    if(w.filter(e => e.Industry == element.industryName).length  == 0 ){
                      w.push(
                        {
                          Industry:element.industryName,
                          BusinessSectors:[{
                              businessSectorId: element.businessSectorId,
                              businessSectorName: element.businessSectorName,
                              min: element.min,
                              average: element.average,
                              median: element.median,
                              max:element.max
                          }]
                        })
                    }
                    else{
                        w.filter(e => e.Industry == element.industryName)[0].BusinessSectors.push({
                          businessSectorId: element.businessSectorId,
                              businessSectorName: element.businessSectorName,
                              min: element.min,
                              average: element.average,
                              median: element.median,
                              max:element.max
                        })
                    }


                    });
                 this.marketPayTrendsList = w
                //  console.log(this.marketPayTrendsList )
      },
       validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
   },
  mounted(){
    this.getPayTrends();
  }
}
</script>

<style>

</style>